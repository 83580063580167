import * as sideBarActionTypes from "../actionTypes/SideBarActionTypes";

const initialState = { isCollapsed: false, selectionMenu: 1, darkMode: 0 };

export const SideBarState = (state = initialState, action) => {
  switch (action.type) {
    case sideBarActionTypes.COLLAPSE_SIDEBAR:
      return {
        ...state,
        isCollapsed: true
      };
    case sideBarActionTypes.EXPAND_SIDEBAR:
      return {
        ...state,
        isCollapsed: false
      };
    case sideBarActionTypes.DARK_MODE:
      return {
        ...state,
        darkMode: 1
      };
    case sideBarActionTypes.LIGHT_MODE:
      return {
        ...state,
        darkMode: 0
      };
    case sideBarActionTypes.SELECTION_MENU:
      return {
        ...state,
        selectionMenu: action.selection
      };
    default:
      return state;
  }
};
