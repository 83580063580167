import React  from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Router, Route, Switch } from 'react-router-dom';

import { history } from 'utils/history';
import VestingSchedule from "components/VestingSchedule/VestingSchedule";

import 'react-toastify/dist/ReactToastify.css';
import {UseWalletInit} from "../hooks/wallet";

const Application = () => {

  UseWalletInit();

  return (
    <React.Fragment>
      <Router history={history}>
        <Switch>
          <Route path="/vesting-schedule" component={VestingSchedule} exact />
          <Route path="/" component={VestingSchedule} exact />
        </Switch>
      </Router>
      <ToastContainer />
    </React.Fragment>
  );
}

export default connect(null, null)(Application);
