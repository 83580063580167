import { combineReducers } from "redux";
import { AuthState } from "./AuthReducer";
import { SideBarState } from './SideBarReducer';
import { AccountState } from './AccountReducer';
import walletReducer from "./walletReducer";

const rootReducer = combineReducers({
  AuthState,
  SideBarState,
  AccountState,
  wallet: walletReducer
});

export default rootReducer;
