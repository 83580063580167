import { Modal } from 'react-bootstrap';
import styles from "./VestingScheduleModal.module.scss";

const VestingScheduleModal = ({ show, onHide }) => {
    return (
        <Modal aria-labelledby="contained-modal-title-vcenter"
            className={styles.vesting_schedule_modal}
               dialogClassName={styles.modal_dialog}
               contentClassName={styles.modal_content}
            show={show} onHide={onHide}>

            <div className={styles.vesting_schedule_modal_container}>
                <div className={styles.close_modal}>
                    <svg style={{ cursor: "pointer" }} onClick={onHide} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M10.0012 13.1931L15.9195 19.2526C16.5132 19.8652 16.8995 19.8714 17.5041 19.2526L18.6915 18.0366C19.2732 17.4411 19.3126 17.0503 18.6915 16.4143L12.4258 10L18.6921 3.58572C19.2789 2.98286 19.2904 2.57601 18.6921 1.96286L17.5046 0.747435C16.8886 0.116578 16.5081 0.145721 15.9201 0.747435L10.0012 6.80686L4.08294 0.748006C3.49494 0.146292 3.11436 0.117149 2.49836 0.748006L1.31094 1.96344C0.712078 2.57658 0.722936 2.98344 1.31094 3.58629L7.57665 10L1.31094 16.4143C0.689793 17.0503 0.722936 17.4411 1.31094 18.0366L2.49779 19.2526C3.09722 19.8714 3.48351 19.8652 4.08236 19.2526L10.0012 13.1931Z" fill="#1C1C28" fillOpacity="0.38" />
                    </svg>
                </div>

                <div className={styles.vesting_schedule_modal_body}>
                    <div className={styles.vesting_schedule_title}>Why your portfolio needs Flourishing AI</div>

                    <div className={styles.vesting_schedule_modal_content}>
                        <div className={styles.vesting_schedule_modal_content_title}>
                            Professional Digital Asset Portfolio Management - powered by Artificial Intelligence:
                        </div>
                        <ul className={styles.list_item_investing}>
                            <li> <span>Self-learning AI portfolio manager, getting smarter from market swings to profit and protect assets</span></li>
                            <li><span>Acts faster than a human trader to jump on profit opportunities</span></li>
                            <li><span>Beautifully clear market indicators and unbiased insights available nowhere else</span></li>
                            <li><span>Spots indicators of "unpredictable" events, and act to protect profits.</span></li>
                            <li><span>You’re in control: Train the AI to your risk tolerance, then put it to work for you 24/7/365</span></li>
                        </ul>
                        <div className={styles.button_wrapper}>
                            <a href="https://dashboard.flourishingcapital.io/auth/register" className={styles.vesting_login_link}>Sign up now</a>
                            <a href="https://dashboard.flourishingcapital.io/auth/login" className={styles.vesting_register_link}>Log in</a>
                        </div>
                    </div>
                </div>
            </div>
        </Modal >
    )
}

export default VestingScheduleModal;