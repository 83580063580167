import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {connectMetaMask} from "../redux/actions/wallet";
import {CONNECTION_TYPES} from "../constants/connectionTypes";

export function UseWalletInit() {
  /*
      Basic initialization logic for the module:
      init scripts, event listeners, etc...
  */
  const dispatch = useDispatch();

  // Init wallet

  useEffect(() => {
    dispatch(connectWallet());
  }, [dispatch]);
}

export const connectWallet = () => dispatch => {
  const connectorId = localStorage.getItem('connectorId');
  if (connectorId === CONNECTION_TYPES.metamask) {
    dispatch(connectMetaMask());
  }
}