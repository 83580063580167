import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store/store';

import Application from './routes/Application';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { Spinner } from 'formcontrols';

import 'index.scss';

export function initialize(rootSelector) {
  render(
    <Provider store={store}>
      <ErrorBoundary>
        <Application />
      </ErrorBoundary>
      <Spinner />
    </Provider>,
    document.getElementById(rootSelector)
  );
}
initialize('root');
