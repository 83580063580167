import { EXCHANGE_MODE } from 'constants/constants';
import * as userActionTypes from "../actionTypes/AccountActionTypes";
import exchange_binance_icon from '../../assets/exchange_binance_icon.svg';
import exchange_bittrex_icon from '../../assets/exchange_bittrex_icon.svg';
import exchange_pancake_icon from '../../assets/exchange_pancake_icon.svg';
import exchange_uniswap_icon from '../../assets/exchange_uniswap_icon.svg';

const initialState = {
  google2FA: undefined,
  autoTrading: false,
  balance: 0,
  unitbalance: "USDT",
  status: {},
  exchanges: [
    {
      key: EXCHANGE_MODE.bittrex,
      icon: exchange_bittrex_icon,
      text: 'Bittrex',
      active: true,
      connected: false,
      view: false,
    },
    {
      key: EXCHANGE_MODE.binance,
      icon: exchange_binance_icon,
      text: 'Binance',
      active: true,
      connected: false,
      view: false,
    },
    {
      key: EXCHANGE_MODE.pancakeswap,
      icon: exchange_pancake_icon,
      text: 'Pancake Swap',
      active: false,
      connected: false,
      view: false,
    },
    {
      key: EXCHANGE_MODE.uniswap,
      icon: exchange_uniswap_icon,
      text: 'Uniswap',
      active: false,
      connected: false,
      view: false,
    }
  ]
};
export const AccountState = (state = initialState, action) => {
  switch (action.type) {
    case userActionTypes.AUTO_TRADING_CHANGE:
      return {
        ...state,
        autoTrading: action.autoTrading
      };
    case userActionTypes.EXCHANGE_CONNECTED:
      let exchanges = state.exchanges
      exchanges = exchanges.map(x => {
        if (x.key === action.exchangekey) {
          const isView = state.exchanges.find(z => z.view === true);
          x.connected = true;
          x.view = isView ? false : true;
        }
        return x;
      });
      return {
        ...state,
        exchanges,
      };
    case userActionTypes.EXCHANGE_VIEW:
      let newExchangeData = state.exchanges;
      newExchangeData = newExchangeData.map(x => {
        if (x.key === action.exchangeView) {
          x.view = true;
        } else {
          x.view = false;
        }
        return x;
      });
      return {
        ...state,
        exchanges: newExchangeData
      };
    case userActionTypes.REFRESH_BALANCE:
      return {
        ...state,
        balance: action.balance
      };
    case userActionTypes.GET_EXCHANGE_STATUS_SUCCESS:
      let exchangesTmp = state.exchanges;
      exchangesTmp = exchangesTmp.map(x => {
        let connectStatus = action.exchangeStatus[x.key];
        x.connected = (connectStatus && connectStatus) > 1 ? true : false;
        if (connectStatus) {
          const isView = state.exchanges.find(z => z.view === true);
          x.view = (isView && isView.key !== x.key) ? false : true;
        }
        return x;
      });
      return {
        ...state,
        exchanges: exchangesTmp
      };
    case userActionTypes.SET_GOOGLE_2FA:
      return {
        ...state,
        google2FA: action.status
      };
    case userActionTypes.EXCHANGE_UNIT_BALANCE:
      return {
        ...state,
        unitbalance: action.unitbalance
      }
    case userActionTypes.GET_ACCOUNT_STATUS:
      return {
        ...state,
        status: action.payload
      }
    default:
      return state;
  }
};
