import moment from "moment";
import flourishingIcon from "../../assets/flourishing_icon.svg";
import styles from "./VestingSchedule.module.scss";
import cn from "classnames";

const VestingScheduleDetail = ({symbol, data}) => {
    const formatDate = (time) => {
        if (time) {
            return moment.unix(time).format("DD/MM/YYYY");
        }
        return moment().format("DD/MM/YYYY");
    }

    return (
        <div className={styles.vesting_content}>
            <span className={styles.vesting_content_title}>{data.scheduleName}</span>
            <div className={styles.vesting_content_row}>
                <div className={styles.vesting_content_column}>
                    <div className={styles.vesting_amount}>
                        <img src={flourishingIcon} alt=""/>
                        <div className={styles.amount_item}>
                            <span style={{color: "#3ADA81"}}
                                  className={styles.vesting_amount_value}>{data.amountVested + " " + symbol}</span>
                            <span className={styles.vesting_amount_label}>Vested</span>
                        </div>
                    </div>
                    <div className={styles.vesting_start_time}>Start date: {formatDate(data.vestStartDay)}</div>
                    <div className={styles.countdown_time}>
                        <span className={styles.countdown_time_label}>Lock Countdown</span>
                        {
                            (data?.isLockEnd ?? true) ?
                                <div className={styles.countdown_time_end}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM16.8155 10.4734C17.215 10.092 17.2297 9.45903 16.8484 9.05953C16.467 8.66003 15.834 8.64531 15.4345 9.02664L10.625 13.6176L8.56548 11.6516C8.16599 11.2703 7.53299 11.285 7.15165 11.6845C6.77031 12.084 6.78503 12.717 7.18452 13.0984L9.93449 15.7234C10.3209 16.0922 10.929 16.0922 11.3154 15.7234L16.8155 10.4734Z"
                                              fill="#3ADA81"/>
                                    </svg>
                                    <span className={styles.countdown_time_end_label}>Lock requirement met</span>
                                </div>
                                :
                                <div style={{color: "#E30000"}} className={styles.countdown_time_container}>
                                    <div className={cn(styles.countdown_time_range, styles.countdown_style)}>
                                        <span
                                            className={styles.countdown_time_value}>{data?.lockCountTime?.d ?? 0}</span>
                                        <span className={styles.countdown_time_label}>Days</span>
                                    </div>
                                    <div className={styles.countdown_time_dot}>:</div>
                                    <div className={cn(styles.countdown_time_range, styles.countdown_style)}>
                                        <span
                                            className={styles.countdown_time_value}>{data?.lockCountTime?.h ?? 0}</span>
                                        <span className={styles.countdown_time_label}>Hours</span>
                                    </div>
                                    <div className={styles.countdown_time_dot}>:</div>
                                    <div className={cn(styles.countdown_time_range, styles.countdown_style)}>
                                        <span
                                            className={styles.countdown_time_value}>{data?.lockCountTime?.m ?? 0}</span>
                                        <span className={styles.countdown_time_label}>Minutes</span>
                                    </div>
                                    <div className={styles.countdown_time_dot}>:</div>
                                    <div className={cn(styles.countdown_time_range, styles.countdown_style)}>
                                        <span
                                            className={styles.countdown_time_value}>{data?.lockCountTime?.s ?? 0}</span>
                                        <span className={styles.countdown_time_label}>Seconds</span>
                                    </div>
                                </div>
                        }
                    </div>
                </div>

                <div className={styles.vesting_content_column}>
                    <div className={styles.vesting_amount}>
                        <img src={flourishingIcon} alt=""/>
                        <div className={styles.amount_item}>
                            <span style={{color: "#E30000"}}
                                  className={styles.vesting_amount_value}>{data.amountNotVested + " " + symbol}</span>
                            <span className={styles.vesting_amount_label}>Remaining</span>
                        </div>
                    </div>
                    <div className={styles.vesting_start_time}/>

                    <div className={styles.countdown_time}>
                        <span className={styles.countdown_time_title}>Vesting Countdown</span>
                        {
                            (data?.isScheduleEnd ?? true) ?
                                <div className={styles.countdown_time_end}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM16.8155 10.4734C17.215 10.092 17.2297 9.45903 16.8484 9.05953C16.467 8.66003 15.834 8.64531 15.4345 9.02664L10.625 13.6176L8.56548 11.6516C8.16599 11.2703 7.53299 11.285 7.15165 11.6845C6.77031 12.084 6.78503 12.717 7.18452 13.0984L9.93449 15.7234C10.3209 16.0922 10.929 16.0922 11.3154 15.7234L16.8155 10.4734Z"
                                              fill="#3ADA81"/>
                                    </svg>
                                    <span className={styles.countdown_time_end_label}>Vesting completed</span>
                                </div>
                                :
                                <div className={styles.countdown_time_container}>
                                    <div className={cn(styles.countdown_time_range, styles.lockdown_style)}>
                                        <span
                                            className={styles.countdown_time_value}>{data?.vestingCountTime?.d ?? 0}</span>
                                        <span className={styles.countdown_time_label}>Days</span>
                                    </div>
                                    <div className={styles.countdown_time_dot}>:</div>
                                    <div className={cn(styles.countdown_time_range, styles.lockdown_style)}>
                                        <span
                                            className={styles.countdown_time_value}>{data?.vestingCountTime?.h ?? 0}</span>
                                        <span className={styles.countdown_time_label}>Hours</span>
                                    </div>
                                    <div className={styles.countdown_time_dot}>:</div>
                                    <div className={cn(styles.countdown_time_range, styles.lockdown_style)}>
                                        <span
                                            className={styles.countdown_time_value}>{data?.vestingCountTime?.m ?? 0}</span>
                                        <span className={styles.countdown_time_label}>Minutes</span>
                                    </div>
                                    <div className={styles.countdown_time_dot}>:</div>
                                    <div className={cn(styles.countdown_time_range, styles.lockdown_style)}>
                                        <span
                                            className={styles.countdown_time_value}>{data?.vestingCountTime?.s ?? 0}</span>
                                        <span className={styles.countdown_time_label}>Seconds</span>
                                    </div>
                                </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default VestingScheduleDetail;