import React, {useState, useEffect} from 'react';
import Web3 from "web3";
import BigNumber from 'bignumber.js';
import cn from 'classnames';

import VestingABI from 'constants/FlourishingAIToken.json';

import { SettingConnectWalletModal } from 'formcontrols';
import VestingScheduleModal from "./VestingScheduleModal";

import VestingScheduleDetail from './VestingScheduleDetail';
import vesting_image from "../../assets/vesting_image.svg";
import AuthLayout from "../AuthLayout/AuthLayout";
import styles from "./VestingSchedule.module.scss";
import {connectMetaMask, switchNetwork} from "../../redux/actions/wallet";
import {useDispatch, useSelector} from "react-redux";
import {formatBlockchainAddress} from "../../utils";
import {trackPromise} from "react-promise-tracker";
import {CONNECTION_TYPES} from "../../constants/connectionTypes";
import NoMetamaskModal from "../../formcontrols/NoMetamaskModal";

const VestingSchedule = () => {
    const [spendableBalance, setSpendableBalance] = useState('Loading...');
    const [totalVested, setTotalVested] = useState('Loading...')
    const [showNotGetSchedules, setShowNotGetSchedules] = useState(false);
    const [symbol, setSymbol] = useState('');
    const [userVestingSchedules, setUserVestingSchedules] = useState([]);
    const [showConnectWallet, setShowConnectWallet] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const {account} = useSelector(state => state.wallet.walletAccount);
    const wrongNetwork = useSelector(state => state.wallet.wrongNetwork);
    const [tokenInfo, setTokenInfo] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        trackPromise(loadingVetstingInformation(account, process.env.REACT_APP_AI_TOKEN_BSC_ADDRESS));
    }, [account])

    const addAIToken = async () => {
        if(!tokenInfo)
            return;

        const { address, symbol, decimals, image } = tokenInfo;
        try {
            if (window.ethereum && window.ethereum.isMetaMask) {
                const params = {
                    type: 'ERC20',
                    options: {
                        address: address,
                        symbol: symbol,
                        decimals: decimals,
                        image: image
                    }
                };

                window.ethereum.request({
                    method: 'wallet_watchAsset',
                    params
                });
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const loadingVetstingInformation = async (address, scAddress) => {
        let interval = null;
        try {
            const vestingBSCContract = new window.web3.eth.Contract(VestingABI.abi, scAddress);
            const timestamp = Math.floor(new Date().getTime() / 1000);
            const symbol = await vestingBSCContract.methods.symbol().call({ from: address });
            const decimals = await vestingBSCContract.methods.decimals().call({ from: address });
            const realDecimals = Web3.utils.toBN(10).pow(Web3.utils.toBN(decimals));
            let userBalance = await vestingBSCContract.methods.balanceOf(address).call({ from: address });
            const formattedBalance = Web3.utils.toBN(userBalance).div(realDecimals);
            const userVestingInfo = await vestingBSCContract.methods.vestingAsOf(timestamp).call({ from: address });
            setTokenInfo({
                address: scAddress,
                symbol: symbol,
                decimals: decimals,
                image: "https://flourishingcapital.io/assets/img/logo-circle.png"
            });

            if (userVestingInfo && userVestingInfo.length > 0) {
                interval = setInterval(() => mappingLoadDataVestingSchedule(symbol, formattedBalance, userVestingInfo), 1000);
            } else {
                setSpendableBalance(formattedBalance);
                setSymbol(symbol);
                setShowNotGetSchedules(true);
            }
        } catch (error) {
            setShowNotGetSchedules(true)
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    }

    const openModalRegister = () => {
        setShowModal(true);
    }

    const onHideModal = () => {
        setShowModal(false);
    }

    const onHandleConnect = () => {
        if (account) {
            switchNetwork('0x38');
        } else {
            setShowConnectWallet(true);
        }
    }

    const onHandleSubmitConnectWallet = () => {
        setShowConnectWallet(false);
        localStorage.setItem('connectorId', CONNECTION_TYPES.metamask);
        dispatch(connectMetaMask());
    }



    const formatTimeShow = (timeleft) => {
        let resultData = {
            d: 0,
            h: 0,
            m: 0,
            s: 0
        }
        if (timeleft && timeleft > 0) {
            resultData.d = Math.floor(timeleft / (60 * 60 * 24));
            resultData.h = Math.floor((timeleft % (60 * 60 * 24)) / (60 * 60));
            resultData.m = Math.floor((timeleft % (60 * 60)) / (60));
            resultData.s = Math.floor((timeleft % (60)));
        }
        return resultData;
    }

    const mappingLoadDataVestingSchedule = (symbol, userBalance, userVestingInfo) => {
        let listVestingSchedule = [...userVestingInfo];
        let totalVested = 0;
        let totalNotVested = 0;
        let totalSpendable = 0;

        listVestingSchedule = listVestingSchedule.map(item => {
            let resultMap = { ...item };
            resultMap.isLockEnd = false;
            resultMap.isScheduleEnd = false;

            let amountOfGrant = Number(Web3.utils.fromWei(item.amountOfGrant, 'ether'));
            let vestDuration = Number(item.vestDuration);
            let cliffDuration = Number(item.cliffDuration);
            let vestStartDay = Number(item.vestStartTimestamp);

            // get information
            let lockCountTime = vestStartDay + cliffDuration;
            let vestingCountTime = vestStartDay + cliffDuration + vestDuration;
            let now = Math.floor(new Date().getTime() / 1000);

            // calculation vested object
            const lockTimeleft = lockCountTime - now;
            if (lockTimeleft > 0) {
                resultMap.lockCountTime = formatTimeShow(lockTimeleft, lockCountTime);
            } else {
                resultMap.isLockEnd = true;
            }

            // format time to show
            const vetstingTimeleft = vestingCountTime - now;
            if (vetstingTimeleft > 0) {
                resultMap.vestingCountTime = formatTimeShow(vetstingTimeleft, vestingCountTime);
            } else {
                resultMap.isScheduleEnd = true;
            }

            let vested = 0;
            let amountNotVested = 0;
            // If there's no schedule, or before the vesting cliff, then the full amount is not vested.
            if (!resultMap.isActive || now < vestStartDay + cliffDuration
            ) {
                // None are vested (all are not vested)
                amountNotVested = amountOfGrant;
            }
            // If after end of cliff + vesting, then the not vested amount is zero (all are vested).
            else if (now >= vestStartDay + (cliffDuration + vestDuration)
            ) {
                // All are vested (none are not vested)
                vested = amountOfGrant;
            }
            // Otherwise a fractional amount is vested.
            else {
                // Compute the exact number of days vested.
                let daysVested = now - (vestStartDay + cliffDuration);

                // Compute the fraction vested from schedule using 224.32 fixed point math for date range ratio.
                // Note: This is safe in 256-bit math because max value of X billion tokens = X*10^27 wei, and
                // typical token amounts can fit into 90 bits. Scaling using a 32 bits value results in only 125
                // bits before reducing back to 90 bits by dividing. There is plenty of room left, even for token
                // amounts many orders of magnitude greater than mere billions.
                vested = amountOfGrant * daysVested / vestDuration;
                amountNotVested = amountOfGrant - vested;
            }

            resultMap.amountVested = formatNumber(vested);
            resultMap.amountNotVested = formatNumber(amountNotVested);

            totalVested += vested;
            totalNotVested += amountNotVested;

            return resultMap;
        });

        totalSpendable = userBalance - totalNotVested;

        setShowNotGetSchedules(false);
        setUserVestingSchedules(listVestingSchedule);
        setTotalVested(formatNumber(totalVested));
        setSpendableBalance(formatNumber(totalSpendable));
        setSymbol(symbol);
    }

    const formatNumber = (value) => {
        try {
            if (value) {
                let result = new BigNumber(value);
                if (result.isInteger()) {
                    return result.toFormat();
                }
                return result.toFormat(4);
            }
            return 0
        } catch (error) {
            return 0;
        }
    }

    const renderClass = () => {
        if (userVestingSchedules.length === 1 && account !== '') {
            return cn(styles.vesting_container, styles.vesting_container_has_one_schedule);
        }

        if (userVestingSchedules.length === 0) {
            return cn(styles.vesting_container, styles.vesting_container_no_schedule);
        }

        return cn(styles.vesting_container, styles.vesting_container_default);
    }


    return (
        <div className="public-area">
            <AuthLayout>
                <div className={renderClass()}>
                    <div className={styles.vesting_title}>
                        <div style={{width: "100%"}}>
                            <label className={styles.vesting_schedule_label}>Vesting Schedule</label>
                        </div>
                        {
                            (account === '' || !account || wrongNetwork) ?
                                <button className={styles.vesting_schedule_connect_btn} onClick={onHandleConnect}>Connect Wallet</button>
                                :
                                <>
                                    <div className={styles.import_token} onClick={addAIToken}>
                                        <span className={styles.import_token_text}>Import AI Into MetaMask</span>
                                    </div>
                                    <div className={styles.vesting_schedule_address}>
                                        <span className={styles.vesting_address}>{formatBlockchainAddress(account)}</span>
                                        <img src={vesting_image} alt=""/>
                                    </div>
                                </>
                        }
                    </div>
                    {
                        (account === '' || !account || wrongNetwork) ?
                            <div className={styles.vesting_is_connected}>
                                <label>Connect to your wallet on Binance Smart Chain to view the vesting schedule.</label>
                            </div>
                            : (showNotGetSchedules) ?
                                <div className={styles.vesting_is_connected}>
                                    <div className={styles.vestingdata_no_schedule}>
                                        <label>No vesting schedule available!</label>
                                        <p/>
                                        <span>Total amount spendable: <label>{spendableBalance + " " + symbol}</label></span>
                                    </div>
                                </div>
                                :
                                <div className={styles.vestingdata_show}>
                                    <div className={styles.vestingdata_show_total}>
                                        <span>Total amount spendable: <label>{spendableBalance + " " + symbol}</label></span>
                                    </div>
                                    <div className={styles.vestingdata_show_total}>
                                        <span>Total vested at this time: <label>{totalVested + " " + symbol}</label> </span>
                                    </div>
                                    <div className={styles.vestingdata_show_content}>
                                        {
                                            userVestingSchedules.map((u, i) => {
                                                return <VestingScheduleDetail symbol={symbol} key={i} data={u}/>
                                            })
                                        }
                                    </div>
                                </div>
                    }

                    <VestingScheduleModal show={showModal} onHide={onHideModal}/>
                    <SettingConnectWalletModal
                        onSubmitHandle={onHandleSubmitConnectWallet}
                        show={showConnectWallet}
                        onHide={() => setShowConnectWallet(false)} >
                    </SettingConnectWalletModal>
                    <NoMetamaskModal />
                </div>
            </AuthLayout>
            <div className={styles.group_ellipse}>
                <div className={styles.group_ellipse_container}>
                    <div className={styles.group_ellipse_1}>
                        <div className={styles.group_ellipse_2}>
                        </div>
                    </div>
                    <div className={styles.boost_connect_dashboard} onClick={openModalRegister}>
                        <div className={styles.boost_connect_dashboard_text}>
                            <label>AI  Boost</label>
                            <label>Your Portfolio</label>
                            <label>Today</label>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default VestingSchedule;