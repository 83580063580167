export const MAX_LENGTH_EMAIL = 325;
export const MAX_LENGTH_PASSWORD = 30;

export const DATE_TIME_FORMAT = "DD-MM-YYYYY";

export const EMAIL_PATTERN =
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PASSWORD_PATTERN = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,30}$/;

export const VERIFY_CODE_PATTERN = new RegExp("^[0-9]{0,6}$");

export const API_TIMEOUT_REQUEST = 1000 * 60 * 5;

export const TIME_VERIFY_CODE = 15 * 60;

export const TYPE_DIALOG_CONFIRM = {
    error: "error",
    success: "success",
    warning: "warning",
    confirm_warning: "confirm_warning",
};

export const ICON_COLOR = {
    emptyPass: "#D2D2D7",
    empty: "#C7C9D9",
    show: "#1D1D1F",
    error: "#E30000",
};

export const EXCHANGE_ARGS = {
    signup: 0,
    deactivate: 1,
    activate: 2,
    getStatus: 3,
};

export const PASSWORD_ERR = {
    valid: 0,
    required: 1,
    notValid: 2,
    notmatch: 3,
    incorrect: 4,
};

export const EMAIL_ERR = {
    valid: 0,
    required: 1,
    notValid: 2,
    notmatch: 3,
    exits: 4,
    other: 5,
    notFound: 6,
};

export const EXCHANGE_MODE = {
    bittrex: 0,
    binance: 1,
    bartertrade: 2,
    flourish: 3,
    stock: 4,
    uniswap: 5,
    pancakeswap: 6,
    none: 7,
};

export const BLACKLIST_MODE = {
    add: 0,
    remove: 1,
    get: 2,
};

export const ACCOUNT_SETTING_MODE = {
    get: 0,
    set: 2,
};

export const unitBalance = {
    BTC: "BTC",
    ETH: "ETH",
    USDT: "USDT",
};

export const PerformanceDetailsMode = {
    MODE_DAILY: 0,
    MODE_WEEKLY: 1,
    MODE_MONTHLY: 2,
    MODE_TRI_MONTHLY: 3,
    MODE_YEARLY: 4,
    MODE_PLACEHOLDER: 999,
};

export const StatsEnum = {
    MODE_USER: 0,
    MODE_SELF: 1,
};

export const StrategyEnum = {
    ANCHOR_HODL: 0,
    ANCHOR_ALTS: 1,
    ANCHOR_ACCUMULATE: 2,
    ANCHOR_PROTECT: 3,
    STABLECOIN_ARBITRAGE: 4,
    STABLECOIN_PROTECT: 5,
    ALTS: 6,
    UNKNOWN: 7,
};

export const CandleChartEnum = {
    MODE_1: 0,
    MODE_5: 1,
    MODE_60: 2,
    MODE_240: 3,
    MODE_1440: 4,
};

export const GOOGLE_AUTH_MODE = {
    ON: 1,
    OFF: 0,
};

export const GOOGLE_AUTH_SETTING_MODE = {
    QR_CODE: 0,
    VERIFY: 1,
    STATUS: 2,
    REMOVE: 3,
};

export const NETWORK_CHAINID = {
    MAINNET: 1,
    ROPSTEN: 3,
    RINKEBY: 4,
    GÖRLI: 5,
    KOVAN: 42,
    BSC_MAINNET: 56,
    BSC_TESTNET: 97,
};

export const PERFOMANCE_DETAIL_GETDATA_ENUM = {
    REAL_DATA: 0,
    MOCK_DATA: 1,
};

export const VESTING_ROUND_ENUM = {
    angel: 1,
    strategic: 2,
    privateA: 3,
    privateB: 4,
    publicRound: 5,
    team: 6,
    reserve: 7,
    operation: 8,
    marketing: 9,
    advisory: 10,
};

export const PORTFOLIO_MODE = {
    GET: 0,
    SELL_ALL: 1,
    UN_MANAGER: 2,
    MANAGE: 3,
    MOCK: 4,
};
