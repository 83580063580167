import {handleActions} from "redux-actions";
import {has, isEmpty} from "lodash";

const initialState = {
  connectWallet: {
    error: null,
    isConnect: false,
    requesting: false,
  },
  walletAccount: {
    balance: 0,
    account: null,
    error: null,
    requesting: false,
  },
  smartContractAddress: null,
  chainId: '',
  wrongNetwork: false,
  nometamask: false,
}

let walletReducer = handleActions({
  /** CONNECT WALLET **/
  CONNECT_WALLET_REQUEST: (state) => ({
    ...state,
    connectWallet: {
      ...state.connectWallet,
      requesting: true,
    },
  }),
  CONNECT_WALLET_SUCCESS: (state) => ({
    ...state,
    connectWallet: {
      ...state.connectWallet,
      requesting: false,
      isConnect: true,
      error: null,
    },
  }),
  CONNECT_WALLET_FAIL: (state, { payload }) => ({
    ...state,
    connectWallet: {
      ...state.connectWallet,
      requesting: false,
      error: payload.error,
    },
  }),

  SET_WRONG_NETWORK: (state, { payload }) => ({
    ...state,
    wrongNetwork: payload,
  }),

  SET_NETWORK: (state, { payload }) => ({
    ...state,
    chainId: payload,
  }),

  SET_WALLET_ACCOUNT: (state, { payload }) => ({
    ...state,
    walletAccount: {
      ...state.walletAccount,
      account: has(payload, 'account') ? payload.account : state.walletAccount.account,
      balance: has(payload, 'balance') ? payload.balance : state.walletAccount.balance,
    },
    connectWallet: {
      ...state.connectWallet,
      ...{ isConnect: has(payload, 'accounts') ? !isEmpty(payload.accounts) : !state.walletAccount.account },
    },
    chainId: has(payload, 'chainId') ? parseInt(payload.chainId) : parseInt(state.chainId)
  }),

  SET_NOMETAMASK: (state, { payload }) => ({
    ...state,
    nometamask: payload
  })

}, initialState)

export default walletReducer;