import Web3 from "web3";
import {createAction, createActions} from 'redux-actions';
import {nodes, testNodes} from "utils/getRpcUrl";

const networkList = process.env.REACT_APP_ALLOWED_NETWORKS && process.env.REACT_APP_ALLOWED_NETWORKS.split(',');
const ALLOWED_NETWORKS = networkList && networkList.length ? networkList : ['0x1'];

export const setChainId = createAction('SET_NETWORK');
export const setWrongNetwork = createAction('SET_WRONG_NETWORK');
export const setWalletAccounts = createAction('SET_WALLET_ACCOUNT');
export const setNoMetamask = createAction('SET_NOMETAMASK');
const { connectWalletRequest, connectWalletSuccess, connectWalletFail } = createActions({
  CONNECT_WALLET_REQUEST: () => { },
  CONNECT_WALLET_SUCCESS: data => ({ data }),
  CONNECT_WALLET_FAIL: error => ({ error })
});

export const setNetwork = (chainId) => (dispatch) => {
  dispatch(setChainId(chainId));

  const connectorId = localStorage.getItem('connectorId');
  if( connectorId !== null){
    dispatch(validateNetwork(chainId));
  }
};

export const validateNetwork = (chainId) => (dispatch, getState) => {
  const {wrongNetwork} = getState().wallet;

  if (chainId) {
    const hexChainId = `${chainId.toString().slice(0,2) === '0x' ? '' : '0x'}${chainId.toString(16)}`;
    const isValid = ALLOWED_NETWORKS.indexOf(hexChainId) !== -1;

    if (!isValid) {
      dispatch(setWrongNetwork(true));
      switchNetwork('0x38');
    } else if (isValid) {
      dispatch(setWrongNetwork(false));
    }
  } else if (wrongNetwork) {
    dispatch(setWrongNetwork(false));
  }
};

export const connectMetaMask = () => async (dispatch) => {
  dispatch(connectWalletRequest());
  if (window.ethereum) {
    dispatch(setNoMetamask(false));
    window.web3 = new Web3(window.ethereum);

    window.ethereum.on('accountsChanged', async (accounts) => {
      if (accounts && accounts[0]) {
        const chainId = parseInt(window.ethereum.chainId);
        const balance = await window.web3.eth.getBalance(accounts[0]);
        dispatch(setWalletAccounts({ account: accounts[0], balance }));
        dispatch(setNetwork(chainId));
      } else {
        dispatch(disconnect());
      }
    });

    window.ethereum.on('chainChanged', async (chainId) => {
      window.web3 = new Web3(window.ethereum);
      const accounts = await window.web3.eth.getAccounts();
      let balance;

      dispatch(setNetwork(chainId));
      if (ALLOWED_NETWORKS.indexOf(chainId) === -1) {
        return dispatch(setWrongNetwork(true));
      }
      if (accounts && accounts[0]) {
        balance = await window.web3.eth.getBalance(accounts[0]);
        dispatch(setWalletAccounts({ account: accounts[0], balance, chainId }));
      } else {
        dispatch(disconnect());
      }
    });

    return window.ethereum.request({ method: 'eth_requestAccounts' })
      .then(async () => {
        dispatch(connectWalletSuccess());
        const chainId = parseInt(window.ethereum.chainId);
        const accounts = await window.web3.eth.getAccounts();
        const balance = await window.web3.eth.getBalance(accounts[0]);

        dispatch(setNetwork(chainId));
        if (ALLOWED_NETWORKS.indexOf(window.ethereum.chainId) === -1) {
          return dispatch(setWrongNetwork(true));
        } else {
          dispatch(setWrongNetwork(false));
        }
        dispatch(setWalletAccounts({ account: accounts[0], balance, chainId }));
        return true;
      })
      .catch(() => {
        dispatch(connectWalletFail());
        return false;
      });

  } else {
    return new Promise((resolve, reject) => {
      const err = 'Metamask not install.';
      dispatch(connectWalletFail(err));
      dispatch(disconnect());
      dispatch(setNoMetamask(true));
      reject(err);
    });
  }
};


const disconnect = () => async dispatch => {
  await dispatch(setWalletAccounts({account: null}));
  await dispatch(setWrongNetwork(false));
  window.localStorage.removeItem('connectorId');
}


export const switchNetwork = (poolChainId) => {
  if (window.ethereum) {
    const chainId = parseInt(poolChainId);
    try {
      window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${chainId.toString(16)}`,
            chainName: 'Binance Smart Chain Mainnet',
            nativeCurrency: {
              name: 'BNB',
              symbol: 'bnb',
              decimals: 18,
            },
            rpcUrls: chainId === 56 ? nodes : testNodes,
            blockExplorerUrls: [chainId === 56 ? 'https://bscscan.com/' : 'https://testnet.bscscan.com/'],
          },
        ],
      });
      return true;
    } catch (e) {
      return false;
    }
  }
  return false;
};