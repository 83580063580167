import Cookies from "js-cookie";
import * as authActionTypes from "../actionTypes/AuthActionTypes";

let token = Cookies.get('token_flourishing_user');
const initialState = token ?
  {
    token: null,
    isAuthenticated: true,
    previousPath: ''
  } :
  {
    token: null,
    isAuthenticated: false,
    previousPath: ''
  };

export const AuthState = (state = initialState, action) => {
  switch (action.type) {
    case authActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.data.token,
        isAuthenticated: true,
      };
    case authActionTypes.LOGOUT:
      Cookies.remove("token_flourishing_user");
      return {
        ...state,
        token: null,
        isAuthenticated: false
      };
    case authActionTypes.PREVIOUS_PATH:
      return {
        ...state,
        previousPath: action.url
      };
    default:
      return state;
  }
};
