import React from "react";
import LogoImage from "assets/logo.png";
import styles from "./AuthLayout.module.scss"
import cn from 'classnames';

const AuthLayout = ({ children }) => {
    return (
        <div className={cn("w-100", "h-100", "default-bg", styles.customMain)} id="custom-main">
            <div className={styles.logoContainer}>
                <div className={cn("d-flex", "w-100", "justify-content-center")}>
                    <div>
                        <img className={styles.flourishingLogo} src={LogoImage} alt="" onClick={() => { window.open('https://flourishingcapital.io/') }} />
                    </div>
                </div>
            </div>

            <div className={cn("w-100", "d-flex", "justify-content-center", styles.formRow)}>
                {children}
            </div>
        </div>
    )
}

export default AuthLayout