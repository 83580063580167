import React from 'react';
import { Modal } from 'react-bootstrap';

import metaMask_icon from '../../assets/metaMask_icon.svg'
import styles from "./SettingConnectWalletModal.module.scss";

const SettingConnectWalletModal = ({ show, onSubmitHandle, onHide }) => {
    const wallets = [
        {
            text: "Meta Mask",
            icon: metaMask_icon
        }
    ]

    return (
        <Modal aria-labelledby="contained-modal-title-vcenter" show={show}
            onHide={onHide}
            contentClassName={styles.connect_wallet_modal}>
            <div className={styles.container}>
                <div className={styles.title}>Connect Wallet</div>
                {
                    wallets.map((item, index) => {
                        return (
                            <div key={index} className={styles.item} onClick={() => onSubmitHandle(item.text)}>
                                <img className={styles.item_icon} src={item.icon} alt=""/>
                                <span className={styles.item_text}> {item.text} </span>
                            </div>
                        )
                    })
                }
            </div>
        </Modal>
    )

}

export default SettingConnectWalletModal;