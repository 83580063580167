import React from "react"
import {Button, Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {setNoMetamask} from "../../redux/actions/wallet";
import {AiOutlineWarning} from "react-icons/ai";

import styles from "./noMetamask.module.scss";

const NoMetamaskModal = () => {
    const dispatch = useDispatch();
    const nometamask = useSelector(state => state.wallet.nometamask);

    const handleClose = () => {
        dispatch(setNoMetamask(false));
    }

    const handleInstall = () => {
        dispatch(setNoMetamask(false));
        window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en');
    }
    return (
        <Modal show={nometamask}
               onHide={handleClose}>
            <Modal.Header className={styles.header}>
                <span><AiOutlineWarning size={"26px"}/> Metamask not installed</span>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleInstall}>Install Metamask</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default NoMetamaskModal;